import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

import { useMemo, useState } from 'react'
import { GetCampaignFragment } from '@/graphql/campaign/fragments/get-campaign'

import EndCustomerFlow from '@/pages/campaign/end-customer-flow'

import { useTranslation } from 'react-i18next'
import { userType } from '../../../end-users/src/utils/user-type'
import { TypeOfUser } from '@/schema/ec-flow-texts.schema'
import { Button } from './button'
import { CaretRight, DeviceMobile } from '@phosphor-icons/react'
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'
import { getEndUserPreviewData } from '../../../server/src/utils'
export default function Layout() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const typeOfUser = userType(pathname)
  const isReferralUser = typeOfUser === TypeOfUser.REFERRAL_USER
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()
  const referralIncentives = campaign.campaignIncentives?.filter(
    (incentive) =>
      (incentive?.referral || incentive?.referral === null) &&
      incentive?.isVisible,
  )
  const incentives = campaign.campaignIncentives?.filter(
    (incentive) => !incentive?.referral && incentive?.isVisible,
  )

  const endUser = getEndUserPreviewData(isReferralUser)
  const theme = useMemo(
    () => campaign?.questionnaire?.theme?.key,
    [campaign?.questionnaire?.theme?.key],
  )
  const [showNotification, setShowNotification] = useState(false)

  if (!id) return <p>Missing id</p>

  const previewLink = `${import.meta.env.VITE_PUBLIC_URL}/end-user-preview-${
    endUser.uniqueCode
  }|${campaign.id}`

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(previewLink)
      .then(() => {
        setShowNotification(true)
        setTimeout(() => {
          setShowNotification(false)
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  return (
    <div className=" w-full">
      <h1 className="leading-3">{t('progress_bar.finalise')}</h1>
      <h2 className="leading-3 text-sm text-gray-2">
        {t('ec_flow_texts.sub_header')}
      </h2>
      <div className={`flex w-full my-4`}>
        <div className="w-8/12">
          <EndCustomerFlow campaign={campaign} />
        </div>

        <div className="py-4 px-4 bg-ui1 rounded-lg grid h-auto overflow-hidden ml-20 origin-top">
          <div className="pb-2 font-light flex justify-center gap-2 text-sm">
            <DeviceMobile size={20} className="my-auto" width={50} />
            {t('mobile_view')}
          </div>
          <div className="origin-top flex justify-center">
            <DeviceFrameset device="iPhone X" color="gold" landscape={false}>
              <div className="flex flex-col overflow-y-auto h-full">
                <div className="pointer-events-none flex-grow">
                  <Outlet
                    context={{
                      campaign: {
                        ...campaign,
                        campaignIncentives: isReferralUser
                          ? referralIncentives
                          : incentives,
                      },
                      endUser: { ...endUser, referral: isReferralUser },
                      theme,
                    }}
                  />
                </div>
              </div>
            </DeviceFrameset>
          </div>
          <div className="w-full flex justify-between items-center pt-4">
            <div className="w-2/3">
              <a href={previewLink} target="_blank" rel="noopener noreferrer">
                <span className="font-bold underline">
                  {t('ec_flow_texts.preview_link')}
                </span>
              </a>
              <p>{t('ec_flow_texts.copy_link')}</p>
            </div>
            <div className="w-1/3">
              <Button
                onClick={copyToClipboard}
                label={t('ec_flow_texts.get_link')}
                className="inline-grid w-40 border border-primary text-primary rounded-md py-1 px-2 hover:bg-primary hover:text-white"
                size="small"
              />
              {showNotification && (
                <span className="bg-gray-800 text-white text-center whitespace-nowrap absolute rounded-lg p-2 transform -translate-y-12 right-0">
                  Link copied to clipboard!
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          primary={true}
          onClick={() => navigate(`/campaigns/confirmation/${id}`)}
          type="submit"
          name="next"
          IconRight={CaretRight}
          label={t('next_step')}
          data-testid="submit"
        />
      </div>
    </div>
  )
}
