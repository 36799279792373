import { useTranslation } from 'react-i18next'
import { FieldValues, SubmitHandler } from 'react-hook-form'
import {
  AlphaTypes,
  RadioGroupWithDescriptionInput,
} from '../../../components/form/custom-types'
import AlphaForm from '../../../components/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ZodSchema } from 'zod'
import { UpdateCampaignDetailsType } from '@/schema/campaign-details.schema'
import { LayoutProps } from '@sourcelabbg/form/lib'
import CampaignButtons from '../../../components/campaign-buttons'
import styles from '../../../components/form/form.module.css'
import cx from 'classnames'
import { AlphaSmallCalendar } from '../../../components/calendar/small-calendar'
import style from '../../campaign/campaign.module.css'
import CUSTOMERS_QUERY from '../../../graphql/campaign/queries/customers'
import { useQuery } from '@apollo/client'
import Loading from '@/components/loading'
import { Customer } from '@/gql'
import { useEffect, useState } from 'react'

export default function CampaignDetailsForm<T extends FieldValues>({
  onSubmit,
  schema,
  values = {} as UpdateCampaignDetailsType,
}: {
  onSubmit: SubmitHandler<T>
  schema: ZodSchema
  values?: UpdateCampaignDetailsType
}) {
  const { t } = useTranslation()
  const { data: customersData, loading: customersLoading } =
    useQuery(CUSTOMERS_QUERY)

  const [currentValues, setCurrentValues] =
    useState<UpdateCampaignDetailsType>(values)
  const [typeOfCampaign, setSelectedType] = useState(values.typeOfCampaign)

  useEffect(() => {
    if (typeOfCampaign === 'online') {
      setCurrentValues((prevValues: UpdateCampaignDetailsType) => ({
        ...prevValues,
        scheduleDateTime:
          prevValues.scheduleDateTime ?? new Date().toISOString(),
      }))
    } else {
      setCurrentValues((prevValues: UpdateCampaignDetailsType) => ({
        ...prevValues,
        scheduleDateTime: null,
      }))
    }
  }, [typeOfCampaign])

  const onChange = (field: string, change: { old: string; new: string }) => {
    if (field === 'typeOfCampaign') {
      setSelectedType(change.new)
    } else {
      setCurrentValues((prevValues: UpdateCampaignDetailsType) => ({
        ...prevValues,
        [field]: change.new,
      }))
    }
  }

  if (customersLoading) return <Loading />

  const campaignDetailsForm: AlphaTypes[] = [
    {
      className: 'gap-28 flex font-sans',
      children: [
        {
          className: 'basis-1/2 lg:basis-3/5 flex-col',
          children: [
            {
              render: () => (
                <h1 className="text-xl mb-0">
                  {t('campaign_details.type_of_campaign')}
                </h1>
              ),
            },
            {
              name: 'customerId',
              type: 'select',
              options:
                customersData.customers?.map((customer: Customer) => ({
                  value: customer.id,
                  label: customer.name,
                  data: customer,
                })) || [],
              uiOptions: {
                label: t('campaign_details.customer'),
              },
            },
            {
              name: 'typeOfCampaign',
              type: 'custom',
              customControlType: 'radioGroupType',
              uiOptions: {
                testId: 'type-of-campaign',
              },
            },
            {
              render: () => (
                <h1 className="text-xl mb-0 mt-4">
                  {t('campaign_categories.title')}
                </h1>
              ),
            },
            {
              name: 'campaignCategory',
              type: 'custom',
              customControlType: 'radioGroupWithDescription',
              uiOptions: {
                testId: 'campaign-category',
                className: 'mb-4',
              },
              options: [
                {
                  name: 'generic',
                  label: t('campaign_categories.generic'),
                  description: t('campaign_categories.generic_description'),
                },
                {
                  name: 'birthday',
                  label: t('campaign_categories.birthday'),
                  description: t('campaign_categories.birthday_description'),
                },
                {
                  name: 'lottery',
                  label: t('campaign_categories.lottery'),
                  description: t('campaign_categories.lottery_description'),
                },
              ],
            } as RadioGroupWithDescriptionInput,
            {
              render: () => (
                <h2 className="text-md font-bold">
                  {t('campaign_details.referral_campaign')}
                </h2>
              ),
            },
            {
              name: 'referralCampaign',
              type: 'custom',
              customControlType: 'switch',
              uiOptions: { label: t('campaign_details.include_referral') },
            },
            {
              render: () => (
                <h1
                  data-testid="campaign-details"
                  className="text-xl mt-12 mb-0"
                >
                  {t('campaign_details.details')}
                </h1>
              ),
            },
            {
              children: [
                {
                  name: 'name',
                  type: 'input',
                  uiOptions: {
                    label: t('campaign_details.campaign_name'),
                    labelClassName: 'text-md text-grey-1',
                    inputType: 'text',
                    testId: 'name',
                  },
                },
                {
                  name: 'companyName',
                  type: 'input',
                  uiOptions: {
                    label: t('campaign_details.company_name'),
                    labelClassName: 'text-md text-grey-1',
                    inputType: 'text',
                    testId: 'company-name',
                  },
                },
              ],
            },
            {
              className: 'flex flex-col max-w-1/2',
              children: [
                {
                  className: 'flex-1 gap-8 flex ',
                  children: [
                    {
                      name: 'startDate',
                      type: 'input',
                      uiOptions: {
                        label: t('campaign_details.start_date'),
                        labelClassName: 'text-md text-grey-1',
                        inputType: 'date',
                        testId: 'start-date',
                        className: 'min-w-1/2',
                      },
                    },
                    {
                      name: 'endDate',
                      type: 'input',
                      uiOptions: {
                        label: t('campaign_details.end_date'),
                        labelClassName: 'text-md text-grey-1',
                        inputType: 'date',
                        testId: 'end-date',
                        className: 'min-w-1/2',
                      },
                    },
                  ],
                },
                {
                  className: 'flex-1 gap-2 flex min-w-1/2',
                  children: [
                    {
                      name: 'scheduleDateTime',
                      type: 'custom',
                      customControlType: 'dateTime',
                      uiOptions: {
                        className: 'flex-1 flex gap-2 min-w-1/2',
                        label: t('campaign_details.send_email_date'),
                        visible: () => typeOfCampaign === 'online',
                      },
                    },
                  ],
                },
              ],
            },
            {
              render: (layoutProps: LayoutProps) =>
                (layoutProps.errors.dates_error && (
                  <div data-testid="dates-error" className="text-primary">
                    {t(
                      `validation_messages.${layoutProps.errors.dates_error?.message}`,
                    )}
                  </div>
                )) || <></>,
            },
            {
              name: 'details',
              type: 'custom',
              customControlType: 'textarea',
              uiOptions: {
                label: t('campaign_details.details'),
                labelClassName: 'text-md text-grey-1',
                testId: 'details',
              },
            },
            {
              name: 'senderAddress',
              type: 'input',
              uiOptions: {
                label: t('campaign_details.sender_address'),
                inputType: 'text',
                testId: 'sender-address',

                labelClassName: 'text-grey-1 text-md font-normal',
              },
            },
            {
              className: cx('w-full grid space-y-0 mt-2', style.senderAddress),
              children: [
                {
                  render: () => (
                    <p className="text-grey-1 text-md font-normal">
                      {t('campaign_details.email_address')}
                    </p>
                  ),
                },
                {
                  name: 'emailAddress',
                  type: 'input',
                  uiOptions: {
                    label: t('campaign_details.email_address_label'),
                    inputType: 'text',
                    testId: 'email-address',

                    labelClassName: 'text-zinc-500 text-sm font-normal',
                  },
                },
              ],
            },
            {
              className: cx('w-full grid space-y-0 mt-2', style.senderAddress),
              children: [
                {
                  render: () => (
                    <p className="text-grey-1 text-md font-normal">
                      {t('campaign_details.privacy_link')}
                    </p>
                  ),
                },
                {
                  name: 'privacyLink',
                  type: 'input',
                  uiOptions: {
                    label: t('campaign_details.privacy_link_description'),
                    inputType: 'text',
                    testId: 'privacy-link',
                    labelClassName: 'text-zinc-500 text-sm font-normal',
                  },
                },
              ],
            },
          ],
        },
        {
          render: () => (
            <div className="grow-1 basis-1/2 lg:basis-2/5 pr-4">
              <AlphaSmallCalendar
                className={cx('box-content', styles.calendarRatio)}
              />
            </div>
          ),
        },
      ],
    },
    {
      render: () => <CampaignButtons />,
    },
  ]

  return (
    <div className="flex gap-6 w-screen items-start">
      <AlphaForm
        fields={campaignDetailsForm}
        values={{
          ...currentValues,
          typeOfCampaign: typeOfCampaign,
        }}
        onSubmit={onSubmit}
        resolver={zodResolver(schema)}
        className="flex-1"
        onFieldChange={onChange}
      />
    </div>
  )
}
