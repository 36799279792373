import { OneCommunityResponseCampaign } from '../../../server/src/graphql'
import { useQuery, useMutation } from '@apollo/client'
import GET_ONE_COMMUNITY_CAMPAIGNS from '@/graphql/admin/queries/one-community-campaigns'
import SYNC_ONE_COMMUNITY_CAMPAIGN from '@/graphql/admin/mutations/sync-one-community-campaign'
import Loading from '@/components/loading'
import { Button } from '@/components/button'
import { useState } from 'react'

export default function AdminPage() {
  const { data, loading } = useQuery(GET_ONE_COMMUNITY_CAMPAIGNS)
  const [syncCampaign] = useMutation(SYNC_ONE_COMMUNITY_CAMPAIGN)
  const [syncingIds, setSyncingIds] = useState<string[]>([])

  if (loading) return <Loading />

  const handleSync = async (campaignId: string) => {
    try {
      setSyncingIds([...syncingIds, campaignId])
      await syncCampaign({
        variables: { campaignId },
      })
      alert('Sync initiated successfully!')
    } catch (error) {
      console.error('Error syncing campaign:', error)
      alert('Error syncing campaign')
    } finally {
      setSyncingIds(syncingIds.filter((id) => id !== campaignId))
    }
  }

  return (
    <div className="bg-ui1 p-4 gap-4 space-y-2 flex flex-col h-full">
      <div className="bg-white px-8 rounded-md">
        <div className="grid grid-cols-5 text-lg font-bold py-8">
          <div>Campaign name</div>
          <div>Category</div>
          <div>Form IDs Entries</div>
          <div>Alphabait Form Entries</div>
          <div>Actions</div>
        </div>
        {data?.getOneCommunityCampaigns?.map(
          (campaign: OneCommunityResponseCampaign) => (
            <div className="grid grid-cols-5 my-4" key={campaign.id}>
              <div>{campaign.name}</div>
              <div>{campaign.campaignCategory}</div>
              <div>
                {campaign?.formIdsEntryCounts || 0}
                {campaign.optionalSettings?.oneCommunity?.formIds && (
                  <span className="text-xs text-gray-500 ml-1">
                    (From ID: {campaign.optionalSettings.oneCommunity.formIds})
                  </span>
                )}
              </div>
              <div>
                {campaign.alphabaitFormIdEntryCount || 0}
                {campaign.optionalSettings?.oneCommunity?.alphabaitFormId && (
                  <span className="text-xs text-gray-500 ml-1">
                    (From ID:{' '}
                    {campaign.optionalSettings.oneCommunity.alphabaitFormId})
                  </span>
                )}
              </div>
              <div>
                <Button
                  primary
                  label={
                    syncingIds.includes(campaign.id) ? 'Syncing...' : 'Sync'
                  }
                  size="small-icon"
                  disabled={syncingIds.includes(campaign.id)}
                  className="border-0 border-transparent hover:border-transparent focus:outline-none focus:ring-transparent w-20"
                  onClick={() => handleSync(campaign.id)}
                />
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  )
}
