import { gql } from '@apollo/client'

export default gql`
  query GetOneCommunityCampaigns {
    getOneCommunityCampaigns {
      id
      name
      campaignCategory
      optionalSettings
      customerId
      formIdsEntryCounts
      alphabaitFormIdEntryCount
      customer {
        id
        name
      }
    }
  }
`
