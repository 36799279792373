// English translation file

const progress_bar = {
  campaign_details: 'Campaign Details',
  data_upload: 'Data Upload',
  questionnaire: 'Questionnaire',
  referral_questionnaire: 'Referral Questionnaire',
  incentive: 'Incentive',
  referral_incentive: 'Referral Incentive',
  compose_creative: 'Compose Creative',
  emails: 'Emails',
  happiness_check: 'Happiness Check',
  finalise: 'Finalise',
}

const campaign_details = {
  customer: 'Customer',
  details: 'Details',
  campaign_name: 'Campaign Name',
  company_name: 'Company Name',
  start_date: 'Start Date',
  end_date: 'End Date',
  postage_preferences: 'Postage Preferences',
  sender_address: 'Sender Address',
  referral_campaign: 'Send a friend',
  include_referral: 'Would you like to include a referral campaign?',
  birthday_campaign: 'Birthday Campaign',
  include_birthday:
    'A birthday campaign enables sending incentives for your client’s birthday',
  type_of_campaign: 'Type of campaign',
  offline: 'Offline',
  offline_description: 'Postcard campaign',
  online: 'Online',
  online_description: 'Email campaign',
  email_address: 'Email Address or URL',
  email_address_label:
    'The Address will appear on the postcard in case of an offline campaign',
  privacy_link: 'Privacy Statement Link',
  privacy_link_description:
    'The link to your privacy statement that will be included in our terms and conditions',
  send_email_date: 'Date time to send email campaign',
}

const languages = {
  en: 'Schakel over naar Engels',
  nl: 'Switch to Nederlands',
}

const navigation = {
  create_campaign: 'Create Campaign',
  dashboard: 'Dashboard',
  campaign_list: 'Campaign list',
  agenda: 'Agenda',
  log_out: 'Log out',
  hi: 'Hi',
  action_panel: 'Action Panel',
  get_started: 'To get started, simply select an action from',
  get_started_2: 'the action panel',
  incentives: 'Incentives',
  sku_builder: 'SKU list',
}

const campaign_list = {
  done: 'Done',
  in_progress: 'In Progress',
  upcoming: 'Upcoming',
  draft: 'Draft',
  campaign_list: 'Campaign List',
  campaign_name: 'CAMPAIGN NAME',
  start_date: 'START DATE',
  end_date: 'END DATE',
  status: 'STATUS',
  claimed_gifts: 'CLAIMED GIFTS',
  details: 'Details',
  edit: 'Edit',
  actions: 'ACTIONS',
  campaign_data: 'CAMPAIGN DATA',
}

const questionnaire = {
  theme: 'Theme',
  select_theme: 'By choosing a theme you’d set the style of your campaign',
  referral_theme_preselected:
    'The theme of the campaign is set in the original questionnaire',
  questionnaire: 'Questionnaire',
  select_questionnaire: 'You can choose and adjust a set of questions below',
  questionnaire_1: {
    name: 'Product / Service Feedback Survey',
  },
  questionnaire_2: { name: 'Customer Satisfaction Survey' },
  review_selected_questionnaire: 'Review Selected Questionnaire',
  referral_campaign: 'Referral Campaign',
  referral_campaign_description:
    'Enables customers to easily share the campaign with their friends and contacts through our referral feature.',
}
const optional_question = {
  title: 'Add New Question',
  description: 'You can add your own question here',
  question: 'Question',
  type: 'Type',
  cancel: 'Cancel',
  add_question: 'Add Question',
  reverse_changes: 'Reverse Changes',
  question_placeholder:
    'How likely is it you would recommend us to a friend or colleague?',
  type_placeholder: 'Open Question',
}
const validation_messages = {
  required: 'This field is required',
  Required: 'This field is required',
  invalid_date: 'Invalid date',
  start_date_too_soon_offline:
    'Please select a date at least 21 days ahead for preparing the creative.',
  start_date_too_soon_online: 'Please select a date at least 1 day ahead',
  campaign_length_too_short: 'The campaign must be at least 1 day long',
  empty_incentive: 'Please, select at least one item',
  invalid_number: 'Please, enter valid number',
  invalid_postcode: 'Invalid post code',
  invalid_email: 'Invalid email format',
  invalid_house_number: 'Invalid house number',
  invalid_phone_number: 'Invalid phone number',
  invalid_url: 'Invalid URL',
  missing_picture: 'Please upload an image to add new incentive.',
}

const end_users = {
  end_users: 'End Users',
  preview: 'Preview',
  data_rows: 'Total number of users:',
  data_check: 'Data Check',
  data_upload: 'Data Upload',
  download_example: 'Download Example File',
  table_header: {
    first_name: 'First Name',
    middle: 'Infix',
    last_name: 'Last Name',
    email: 'Email',
    phone_number: 'Phone Number',
    postal_code: 'Post Code',
    city: 'City',
    street_name: 'Street Name',
    house_number: 'House number',
    country: 'Country',
    birthday: 'Birthday',
    sku: 'SKU',
    digital_code: 'Digital Code',
    postnl_code: 'Dutch post Code',
    reference_code: 'Reference Code',
  },
  map_field_placeholder: '[Column Name]',
  missing_data: 'Looks like some data is missing',
  missing_data_text:
    'The following data is missing from the upload. Please upload a new file with the missing data.',
  upload: 'UPLOAD',
  missing: 'MISSING',
  errors: {
    firstName: 'First name',
    lastName: 'Last name',
    middle: 'Middle name',
    email: 'Email',
    sku: 'SKU',
    postnlCode: 'Dutch post code',
    referenceCode: 'Reference Code',
    postcode: 'Post code',
    postalCode: 'Post code',
  },
  ok: 'Ok, got it',
  missing_sku_codes:
    'The listed SKU codes below are missing from the existing SKU list',
}

const file_upload = {
  a: 'a',
  or: 'or',
  upload_file: 'Upload a file',
  drag_and_drop: 'or drag and drop',
  file: 'file',
}

const incentives = {
  title: 'Incentive',
  referral_incentives_title: 'Referral',
  select: 'Select',
  selected: 'Selected',
  types: {
    unique_digital: 'Unique Digital Gift Code',
    universal_digital: 'Universal Digital Gift Code',
    physical: 'Physical Incentive',
    intersolve: 'Intersolve integration',
  },
  sku_code: 'SKU Code',
  digital_code: 'Universal Digital Gift Code',
}

const add_incentive = {
  add_incentive: 'Add Incentive',
  upload_here: 'You can upload your own incentive here',
  name: 'Incentive Name',
  price: 'Price',
  description: 'Description',
  category: 'Category',
  type: 'Type',
  customer: 'Customer',
  download_example: 'Download Example File',
  upload_voucher_code_file: 'Upload Voucher codes',
  shop: 'Shop',
}

const compose_creative = {
  title: 'Compose creative',
  sub_title: 'Type of campaign',
  offline: 'Offline',
  offline_post_card: 'Post card campaign',
  online: 'Online',
  online_email: 'Email campaign',
  postcard: 'Card',
  postcard_description: 'Post card campaign',
  flyer: 'Flyer',
  flyer_description: 'Flyer campaign',
}

const offline = {
  title_design_front: 'Post Card Design Front',
  content_design_front: 'Here you can upload your own design of the post card.',
  post_card_design: 'Post Card Design',
  title_design_back: 'Post Card Design Back',
  content_design_back:
    'Here you can upload your own logo and add text to the card',
  logo: 'Logo',
  text_on_the_back: 'Text on the back',
  landing_text_back: 'Landing text back',
  heading: 'Heading',
  add_text: 'Add text',
  preview: 'Preview',
  special_gift_awaited: 'Special Gift Awaited!',
  add_text_placeholder: 'Claim Your Incentive as our valued client!',
  form_of_address: 'Form of address',
  card_design: 'Card Design',
  info_card: {
    card_requirements: 'Card Requirements',
    post_card_design: 'Post Card Design',
    safety_margin: 'Safety Margin',
    cutting_margin: 'Cutting Margin',
    yellow: 'Yellow',
    red: 'Red',
    safety_size: ': 148 x 210mm',
    cutting_size: ': 154 x 216mm',
    safety_margin_description:
      'The safety margin (yellow border) indicates how far important elements, such as texts and logos, should remain from the edge of your design. This prevents the text from being partially cut off or standing on the edge of your printed matter.',
    cutting_margin_description:
      'Cutting margin (bleed) is the margin you keep in your design to prevent (white) cutting lines at the edge of your printed matter. We ask for a cutting margin of 3 mm per side.',
    tip: 'TIP',
    tip_description:
      'Let your background continue up to and including the red edge (the bleed), as there is minimal play here during cutting. This prevents white edges after cutting and ensures that the background continues nicely on all sides.',
  },
}

const confirmation = {
  title: 'Your Campaign is all set!',
  description:
    'You can find it and manage all your campaigns in the campaign list',
  go_to_campaign_list: 'Go to Campaign List',
}

const happiness_check = {
  module: 'Happiness Check Module',
  include: 'Include the happiness check module',
  really_negative: 'Really Negative',
  negative: 'Negative',
  alright: 'It was alright',
  positive: 'Positive',
  really_positive: 'Really positive',
}

const online = {
  email_title: 'Email Design',
  redeem_gift: 'Redeem gift',
  email_text: 'E-mail Text',
}

const print_files_download = {
  modal_title: 'Secure File Download',
  modal_description: `This file is protected with a password to ensure utmost security.
  To access it, please enter the correct password below. Handle it
  with care and only share it with authorized recipients.`,
  download: 'Download File',
  copied: 'Copied to clipboard',
}

const incentive_categories = {
  personal_care: 'Personal care',
  home: 'Home',
  other: 'Other',
  foods_and_drinks: 'Food & Drinks',
}

const dashboard_labels = {
  dashboard: 'Dashboard',
  general_campaign: 'General',
  referral_campaign: 'Referral',
  campaign_overview: 'Campaign Overview',
  campaign_performance: 'Campaign Performance',
  requested_incentives: 'Requested Incentives',
  by_share: 'by share',
  by_type: 'by type',
  over_time: 'over time',
  since_the_beginning_of_the_campaign: 'since beginning of campaign',
  happiness_check: 'Happiness Check',
  incentiveVoucherReserved: 'Gift vouchers reserved',
  incentiveVoucherUsed: 'Gift vouchers used',
  incentiveVoucherExpired: 'Gift vouchers expired',
  incentive_code_used: 'Incentive Code Used',
  overview: {
    campaign_reached: 'Total Reached',
    scanned_qr_codes: 'Scanned QR Codes',
    referral_link_opens: 'Referral Link Opens',
    questionnaire_started: 'Questionnaire Started',
    questionnaire_completed: 'Questionnaire Completed',
    incentives_requested: 'Incentives Requested',
    scanned_qr_codes_online: 'Unique clicks on the link',
    referral_link_opens_online: 'Referral Link Opens',
    questionnaire_started_online: 'Started the flow',
    questionnaire_completed_online: 'Questionnaire Completed',
    incentives_requested_online: 'Incentives Requested',
    incentiveVoucherReserved: 'Gift vouchers reserved',
    incentiveVoucherUsed: 'Gift vouchers used',
    incentiveVoucherExpired: 'Gift vouchers expired',
  },
  performance_by_time: {
    general: {
      reached: 'Nr. QR codes scanned / web visits',
      incentiveRequested: 'Incentives requested',
      questionnaireStarted: 'Started Questionnaires',
      questionnaireCompleted: 'Completed Questionnaires',
      incentiveVoucherReserved: 'Gift vouchers reserved',
      incentiveVoucherUsed: 'Gift vouchers used',
      incentiveVoucherExpired: 'Gift vouchers expired',
      resetVoucherCode: 'Voucher code reset',
    },
    referral: {
      reached: 'Referral link opens',
      incentiveRequested: 'Incentives requested',
      questionnaireStarted: 'Started Questionnaires',
      questionnaireCompleted: 'Completed Questionnaires',
      incentiveVoucherReserved: 'Gift vouchers reserved',
      incentiveVoucherUsed: 'Gift vouchers used',
      incentiveVoucherExpired: 'Gift vouchers expired',
      resetVoucherCode: 'Voucher code reset',
    },
  },
}

const tooltips = {
  generate_files: 'Generate Files',
  send_email: 'Send Email',
  download_user_data: 'Download User Data',
  download_creative: 'Download Print Files',
}

const flyer = {
  title: 'Flyer design',
  front: 'Front',
  info_card: {
    flyer_requirements: 'Flyer Requirements',
    safety_margin: 'Safety Margin',
    cutting_margin: 'Cutting Margin',
    yellow: 'Yellow',
    red: 'Red',
    safety_size: ': 210 x 148mm',
    cutting_size: ': 216 x 154mm',
  },
}

const campaign_categories = {
  title: 'Campaign Category',
  generic: 'Generic',
  birthday: 'Birthday',
  lottery: 'Lottery',
  generic_description:
    'Simply upload your data list and send tailored creatives and incentives to your audience',
  birthday_description:
    'A birthday campaign enables sending incentives for your client’s birthday',
  lottery_description:
    'Retrieve your lottery draw results and share them with your customers',
}

const api_integration = {
  generic_header: 'Generic Campaign',
  generic_text:
    'Please select the date range for the customer data you want to download from the API.',
  lottery_text:
    'Please select one or several lottery submission forms for the customer data you want to download from the API.',
  lottery_header: 'Lottery Campaign',
  sync: 'Sync API Connection',
}

const incentive_errors_modal = {
  delete_error_modal_title: "This incentive item can't be deleted",
  delete_incentive_item_is_used:
    'Incentive item is used {{numberOfCampaigns}} campaign',
  delete_incentive_with_used_voucher_codes:
    'The attached incentive has used voucher codes',
  update_error_modal_title:
    'This gift is featured in {{numberOfCampaigns}} campaigns',
  update_incentive_item_is_used:
    'The selected incentive is currently featured in {{numberOfCampaigns}} campaigns. If you want to deactivate it you should deselect the incentive from the campaigns',
}

const incentives_store = {
  title: 'Incentives',
  new_incentive: 'New Incentive',
  featured: 'Featured',
  product_info: 'Product info',
  price: 'Price',
  category: 'Category',
  description: 'Description',
  claimed: 'Claimed',
  disabled_active: 'Disabled/Active',
  pcs: 'pcs',
  add_incentive: 'Add New Incentive',
  active: 'Active',
  status: 'Incentive Status',
  uploaded_files: 'Uploaded Files',
  add: 'Add Incentive',
  no_incentives: 'No incentives added for this customer yet.',
  please_add: 'Please add a new incentive',
  featured_tooltip: 'Featured in {{numberOfCampaigns}} campaigns',
  previous: 'Previous',
  next: 'Next',
  all_customers: 'All Customers',
  all_categories: 'All Categories',
  search_placeholder: 'Search Incentive',
  pagination_info:
    'Page {{currentPage}} of {{totalPages}} ({{startIndex}}-{{endIndex}} from {{totalIncentives}} row(s) shown)',
}

const ec = {
  hello: 'Hello',
  dear: 'Dear',
  yes_button: 'Yes! Claim my gift',
  back: 'Back',
  next: 'Next',
  happiness_check_button: 'Happiness check',
  finish: 'Finish',
  home: {
    header: 'Congratulations!',
    text: 'Check out the amazing prize you have won.',
  },
  choose_incentive: {
    title: 'A Gift for you!',
    sub_title:
      'As our valued customer you can claim your gift now and be surprised.',
    header: 'CHOOSE ONE GIFT',
    header_lottery: 'YOUR PRIZE',
    validation: 'Please select an incentive to continue',
    code_has_expired:
      'Your voucher code has expired, please select an incentive again',
  },
  happiness_check: {
    title: 'Let us know about your experience',
  },
  invite: {
    title: 'Send a gift from us to your friends as well',
    sub_title: 'Just share the link below',
    share_msg: 'I have a gift for you',
    share_msg_heart_foundation:
      'As a collector for the Heart Foundation, I got to choose a gift. You can too! What will you choose?',
    copy: 'Copy',
    share: 'Share',
  },
  questionnaire: {
    title: 'A few more steps to go',
    termsAndConditions: {
      text1: 'Yes, I would like to receive a gift and agree that:',
      text2: 'My personal data will be used to process my order',
      text3: 'may contact me for relevant campaigns',
      text4:
        'Your personal data is necessary to process the order. Your personal data will be protected in accordance with the privacy policy ofYour personal data is necessary to process the order. Your personal data will be protected in accordance with the privacy policy of',
    },
  },
  success: {
    title: 'Your gift is on the way!',
    sub_title: 'Check your email for more details',
    flyer_title: 'Thank you!',
    title_with_happiness_check: 'All set let us know about your experience',
  },
  expired: {
    title: "Oops! This QR Code didn't work out.",
    description:
      "It might be because the campaign expired or the gift's already been claimed.",
    termsAndConditions: 'Terms and Conditions',
  },
}
const ec_flow_texts = {
  home: 'Home Page',
  incentive: 'Incentive Page',
  questionnaire: 'Questionnaire Page',
  invite: 'Invite Page',
  happiness_check: 'Happiness Check Page',
  success: 'Success Page',
  expired: 'Expired Page',
  sub_header:
    'You can choose and adjust the text of the screens for your end customer below',
  end_user: 'End User',
  referral: 'Referral',
  original_receiver: 'Original receiver',
  invited: 'Received by invite',
  header: 'Header',
  text: 'Text',
  termsAndConditions: 'Terms & Conditions text',
  termsAndConditionsEndUser: 'Terms & Conditions for end user',
  termsAndConditionsReferral: 'Terms & Conditions for referral user',
  termsAndConditionsMarketing: 'Terms & Conditions for marketing',
  termsAndConditionsSummary: 'Terms & Conditions Summary',
  subject: 'Subject',
  email_text: 'E-mail Text',
  preview_link: 'Preview_link',
  copy_link: 'Copy link to preview directly in your browser/mobile phone',
  get_link: 'Get link',
}

const emails = {
  header: 'Emails',
  text: 'Here you can change the email text the your end customers are going to receive',
}
const sku_builder = {
  add_sku: 'New SKU Code',
  update_sku: 'Update SKU',
  sku_code: 'SKU Code',
  sku_list: 'SKU List',
  no_sku: 'SKU not Exist',
  please_add_sku: 'Please add SKU Code',
  incentives: 'Incentive Store',
  search_placeholder: 'Search',
  add_sku_code: 'Add SKU Code',
  update_sku_code: 'Update SKU Code',
  featured_tooltip: 'Featured in {{numberOfIncentives}} incentives',
  assigned: 'Assigned',
  assign_incentive: 'Assign Incentive',
}

const translation = {
  emails,
  ec_flow_texts,
  ec,
  locale: 'en-US',
  yes: 'Yes',
  no: 'No',
  save_draft: 'Save Draft',
  next_step: 'Next Step',
  save_campaign: 'Save Campaign',
  language: 'Language',
  calendar: 'Calendar',
  loading: 'Loading',
  create: 'Create',
  close: 'Close',
  details: 'Details',
  cancel: 'Cancel',
  formal: 'Formal',
  informal: 'Informal',
  save_to_preview: 'Save to load preview',
  download_front_pdf: 'Download front pdf',
  download_back_pdf: 'Download back pdf',
  upload_csv_error:
    'The file is not in the correct format. Please use the same format as the format in the example file!',
  repeat_incentive: 'Add same incentives for referral campaign',
  dear: 'Dear',
  hello: 'Hello',
  current_month: 'Current month',
  required: 'Required',
  sku_not_exist: 'This SKU code "{{sku}}" does not exist in uploaded data',
  sku_exist: 'This SKU code "{{sku}}" already exist',
  skuCodes_not_match_data_upload:
    'SKU codes "{{sku}}" exist in the uploaded data but not in the selected incentive items',
  campaigns_on: 'campaigns on',
  delete: 'Delete',
  delete_campaign: 'Delete Campaign',
  are_you_sure: 'Are you sure you want to delete this campaign?',
  deleting_campaign:
    'Deleting the campaign will remove it from the list and the dashboard.',
  multiselect_placeholder: 'Select an option',
  previous_campaign: 'Previous Campaign',
  day: 'Day',
  edit: 'Edit',
  disable: 'Disable',
  api_integration,
  next: 'Next Screen',
  save: 'Save',
  mobile_view: 'Mobile View',
  pick_a_date: 'Pick a date',
  salutation: 'Salutation (Aanhef)',
  previous_screen: 'Previous Screen',
  languages,
  progress_bar,
  campaign_details,
  navigation,
  campaign_list,
  questionnaire,
  validation_messages,
  end_users,
  file_upload,
  optional_question,
  incentives,
  add_incentive,
  compose_creative,
  offline,
  confirmation,
  happiness_check,
  online,
  print_files_download,
  incentive_categories,
  dashboard_labels,
  tooltips,
  flyer,
  campaign_categories,
  incentive_errors_modal,
  incentives_store,
  sku_builder,
}

export default translation

export type Translations = typeof translation
