// Dutch translation file
import { Translations } from '../i18n/en'

const progress_bar = {
  campaign_details: 'Campagnedetails',
  data_upload: 'Gegevens uploaden',
  questionnaire: 'Vragen',
  referral_questionnaire: 'Vragen voor vrienden',
  incentive: 'Cadeau',
  referral_incentive: 'Cadeau voor een vriend',
  compose_creative: 'Creatie toevoegen',
  emails: 'Emails',
  happiness_check: 'Happiness check',
  finalise: 'Afronden',
}

const campaign_details = {
  customer: 'Klant',
  details: 'Details',
  campaign_name: 'Campagnenaam',
  company_name: 'Bedrijfsnaam',
  start_date: 'Begindatum',
  end_date: 'Einddatum',
  postage_preferences: 'Post voorkeuren',
  sender_address: 'Afzenderadres',
  referral_campaign: 'Stuur een vriend',
  include_referral:
    'Wilt u de mogelijkheid bieden cadeaus aan vrienden te geven?',
  birthday_campaign: 'Verjaardagcampagne',
  include_birthday:
    'Met een verjaardagcampagne kunt u cadeaus versturen voor de verjaardag van uw klant',
  type_of_campaign: 'Campagnetype',
  offline: 'Offline',
  offline_description: 'Ansichtkaart of Flyer campagne',
  online: 'Online',
  online_description: 'E-mail campagne',
  email_address: 'E-mailadres of een URL',
  email_address_label:
    'Het adres zal verschijnen op de ansichtkaart in geval van een offline campagne',
  privacy_link: 'Privacyverklaring Link',
  privacy_link_description:
    'De link naar jouw privacy statement. Deze wordt getoond aan de deelnemers.',
  send_email_date: 'Moment waarop de email campagne verzonden wordt',
}

const languages = {
  en: 'Switch to English',
  nl: 'Switch to Dutch',
}

const navigation = {
  create_campaign: 'Maak campagne',
  dashboard: 'Dashboard',
  campaign_list: 'Campagne overzicht',
  agenda: 'Agenda',
  log_out: 'Uitloggen',
  hi: 'Hoi',
  action_panel: 'Acties',
  get_started: 'Om aan de slag te gaan, selecteert u eenvoudig',
  get_started_2: 'een van deze acties',
  incentives: 'Cadeaus',
  sku_builder: 'SKU list',
  search_placeholder: 'Search for SKU or Incentive',
}

const campaign_list = {
  done: 'klaar',
  in_progress: 'In uitvoering',
  upcoming: 'Komende',
  draft: 'Concept',
  campaign_list: 'Campagne overzicht',
  campaign_name: 'CAMPAGNENAAM',
  start_date: 'begindatum',
  end_date: 'EINDDATUM',
  status: 'STATUS',
  claimed_gifts: 'GECLAIMDE cadeaus',
  details: 'Details',
  edit: 'Bewerk',
  actions: 'Acties',
  campaign_data: 'CAMPAGNE DATA',
}

const questionnaire = {
  theme: 'Thema',
  select_theme: 'Door een thema te kiezen bepaal je de stijl van je campagne',
  referral_theme_preselected:
    'The theme of the campaign is set in the original questionnaire nl',
  questionnaire: 'Vragenlijst',
  select_questionnaire: 'Hieronder kunt u een set vragen kiezen en aanpassen',
  questionnaire_1: {
    name: 'Persoonlijk',
  },
  questionnaire_2: { name: 'Tevredenheid / interesse' },
  review_selected_questionnaire: 'Pas geselecteerde vragenlijst aan',
  referral_campaign: 'Send a friend campagne',
  referral_campaign_description:
    'Klanten kunnen de campagne eenvoudig delen met hun vrienden en contacten via onze send a friend functionaliteit.',
}

const validation_messages = {
  required: 'Dit veld is verplicht',
  Required: 'Dit veld is verplicht',
  invalid_date: 'Ongeldige datum',
  start_date_too_soon_offline: 'Startdatum moet minimaal over 21 dagen zijn',
  start_date_too_soon_online: 'Startdatum moet minimaal over 1 dag zijn',
  campaign_length_too_short: 'De campagne moet minimaal 1 dag lang zijn',
  empty_incentive: 'Selecteer ten minste één item',
  invalid_number: 'Voer geldig nummer in',
  invalid_postcode: 'Ongeldige postcode',
  invalid_email: 'Ongeldige e-mailindeling',
  invalid_house_number: 'Ongeldig huisnummer',
  invalid_phone_number: 'Ongeldig telefoonnummer',
  invalid_url: 'Ongeldige URL',
  missing_picture: 'Please upload an image to add new incentive. nl',
}

const end_users = {
  end_users: 'Eindgebruiker',
  preview: 'Voorbeeld',
  data_rows: 'Totaal aantal gebruikers:',
  data_check: 'Gegevens controleren',
  data_upload: 'Gegevens uploaden',
  download_example: 'voorbeeldbestand',
  table_header: {
    first_name: 'Voornaam',
    middle: 'Tussenvoegsel',
    last_name: 'Achternaam',
    email: 'E-mail',
    phone_number: 'Telefoonnummer',
    postal_code: 'Postcode',
    city: 'Plaats',
    street_name: 'Straatnaam',
    house_number: 'Huisnummer',
    country: 'Land',
    birthday: 'Geboortedag',
    sku: 'SKU',
    digital_code: 'Digital Code',
    postnl_code: 'Dutch post Code nl',
    reference_code: 'Reference Code nl',
  },
  map_field_placeholder: '[Kolomnaam]',
  missing_data: 'Het lijkt erop dat er gegevens ontbreken',
  missing_data_text:
    'De volgende gegevens ontbreken in de upload. Upload een nieuw bestand met de ontbrekende gegevens.',
  upload: 'UPLOADEN',
  missing: 'MISSEND',
  errors: {
    firstName: 'Voornaam',
    middle: 'Tussenvoegsel',
    lastName: 'Achternaam',
    email: 'Email',
    sku: 'SKU',
    postnlCode: 'Dutch post code nl',
    referenceCode: 'Reference Code nl',
    postcode: 'Postcode',
    postalCode: 'Postcode',
  },
  ok: 'OK ik snap het',
  missing_sku_codes:
    'De onderstaande SKU-codes ontbreken in de bestaande SKU-lijst',
}

const file_upload = {
  a: 'een',
  or: 'of',
  upload_file: 'Upload',
  drag_and_drop: 'of sleep',
  file: 'bestand',
}

const optional_question = {
  title: 'Nieuwe vraag toevoegen',
  description: 'U kunt hier uw eigen vraag toevoegen',
  question: 'Vraag',
  type: 'Type',
  cancel: 'Annuleren',
  add_question: 'Vraag toevoegen',
  reverse_changes: 'Wijzigingen ongedaan maken',
  question_placeholder:
    'Hoe waarschijnlijk is het dat je ons zou aanbevelen aan een vriend of collega?',
  type_placeholder: 'Open Vraag',
}
const compose_creative = {
  title: 'Creatie toevoegen',
  sub_title: 'Campagnetype',
  offline: 'Offline',
  offline_post_card: 'Ansichtkaart campagne',
  online: 'Online',
  online_email: 'Email campagne',
  postcard: 'Ansichtkaart',
  postcard_description: 'Ansichtkaart campagne',
  flyer: 'Flyer',
  flyer_description: 'Flyer campagne',
}

const incentives = {
  title: 'Cadeaus',
  referral_incentives_title: 'Vrienden',
  select: 'Selecteer',
  selected: 'Geselecteerd',
  types: {
    unique_digital: 'Unieke digitale cadeaucode',
    universal_digital: 'Universele digitale cadeaucode',
    physical: 'Fysieke cadeaus',
    intersolve: 'Intersolve integration',
  },
  sku_code: 'SKU Code',
  digital_code: 'Universele digitale cadeaucode',
}

const add_incentive = {
  add_incentive: 'Cadeau toevoegen',
  upload_here: 'U kunt uw eigen cadeau hier uploaden',
  name: 'Naam van het cadeau',
  price: 'Prijs',
  description: 'Omschrijving',
  category: 'Categorie',
  type: 'Type',
  customer: 'Klant',
  download_example: 'Download voorbeeldbestand',
  upload_voucher_code_file: 'Upload waardeboncodes',
  shop: 'Winkel',
}

const offline = {
  title_design_front: 'Ansichtkaart ontwerp voorkant',
  content_design_front:
    'Hier kunt u uw eigen ontwerp van de ansichtkaart uploaden.',
  post_card_design: 'Ansichtkaart ontwerp',
  title_design_back: 'Ansichtkaart ontwerp achterkant',
  content_design_back:
    'Hier kun je je eigen logo uploaden en tekst aan de kaart toevoegen',
  logo: 'Logo',
  text_on_the_back: 'Tekst op de achterkant van de kaart',
  landing_text_back: 'Tekst op de landingspagina',
  heading: 'Titel',
  add_text: 'Tekst toevoegen',
  preview: 'Voorbeeld',
  special_gift_awaited: 'Speciaal een cadeau voor u!',
  add_text_placeholder: 'Claim uw cadeau!',
  form_of_address: 'Aanspreekvorm',
  card_design: 'Kaart ontwerp',
  info_card: {
    card_requirements: 'Kaartvereisten',
    post_card_design: 'Ansichtkaart ontwerp',
    safety_margin: 'Veiligheidsmarge',
    cutting_margin: 'Snijmarge',
    yellow: 'Geel',
    red: 'Rood',
    safety_size: ': 148 x 210mm',
    cutting_size: ': 154 x 216mm',
    safety_margin_description:
      "De veiligheidsmarge (gele rand) geeft aan hoe ver belangrijke elementen, zoals teksten en logo's, van de rand van uw ontwerp moeten blijven. Hiermee voorkomt u dat de tekst gedeeltelijk wordt afgesneden of op de rand van uw drukwerk komt te staan.",
    cutting_margin_description:
      'Snijmarge (bleed) is de marge die je in je ontwerp aanhoudt om (witte) snijlijnen aan de rand van je drukwerk te voorkomen. Wij vragen een snijmarge van 3 mm per zijde.',
    tip: 'Tip',
    tip_description:
      'Laat je achtergrond doorlopen tot en met de rode rand (de afloop), hier zit namelijk minimale speling tijdens het knippen. Dit voorkomt witte randen na het uitsnijden en zorgt ervoor dat de achtergrond aan alle kanten mooi doorloopt.',
  },
}

const confirmation = {
  title: 'Uw Campagne is helemaal klaar!',
  description: 'U vindt en beheert al uw campagnes in het campagne overzicht',
  go_to_campaign_list: 'Ga naar campagne overzicht​',
}

const happiness_check = {
  module: 'Reviewscore',
  include: 'Neem de reviewscore op',
  really_negative: 'Zeer slecht',
  negative: 'Matig',
  alright: 'Gemiddeld',
  positive: 'Goed',
  really_positive: 'Uitstekend',
}
const online = {
  email_title: 'E-mail ontwerp',
  redeem_gift: 'Cadeau inwisselen',
  email_text: 'E-mail Tekst',
}

const print_files_download = {
  modal_title: 'beveiligde bestandsdownload',
  modal_description: `Dit bestand is beveiligd met een wachtwoord om de grootst mogelijke veiligheid te garanderen. Voer hieronder het juiste wachtwoord in om toegang te krijgen. Ga er zorgvuldig mee om en deel het alleen met geautoriseerde ontvangers.`,
  download: 'Download bestand',
  copied: 'Gekopieerd naar het klembord',
}

const incentive_categories = {
  personal_care: 'Persoonlijke verzorging',
  home: 'Huis',
  other: 'Overige',
  foods_and_drinks: 'Eten & Drinken',
}

const dashboard_labels = {
  dashboard: 'Dashboard',
  general_campaign: 'Algemeen',
  referral_campaign: 'Vrienden',
  campaign_overview: 'Campagne overzicht',
  campaign_performance: 'Campagneprestaties',
  requested_incentives: 'Aangevraagde cadeaus',
  by_share: 'аandeel',
  by_type: 'per type',
  over_time: 'in de loop van de tijd',
  since_the_beginning_of_the_campaign: 'sinds het begin van de campagne',
  happiness_check: 'Happiness Check',
  incentiveVoucherReserved: 'Cadeau vouchers gereserveerd',
  incentiveVoucherUsed: 'Cadeau vouchers gebruikt',
  incentiveVoucherExpired: 'Cadeau vouchers verlopen',
  incentive_code_used: 'Giftcards aangevraagd',
  overview: {
    campaign_reached: 'Totaal bereik',
    scanned_qr_codes: 'Gescande QR-codes',
    referral_link_opens: 'Aantal links gestuurd naar vrienden geopend',
    questionnaire_started: 'Vragenlijst gestart',
    questionnaire_completed: 'Vragenlijst voltooid',
    scanned_qr_codes_online: 'E-mail geopend',
    referral_link_opens_online: 'Referral Link Opens',
    questionnaire_started_online: 'Begonnen aan de flow',
    questionnaire_completed_online: 'Activatie voltooid',
    incentives_requested_online: 'Incentives Requested',
    incentives_requested: 'Cadeaus aangevraagd',
    incentiveVoucherReserved: 'Cadeau vouchers gereserveerd',
    incentiveVoucherUsed: 'Cadeau vouchers gebruikt',
    incentiveVoucherExpired: 'Cadeau vouchers verlopen',
  },
  performance_by_time: {
    general: {
      reached: 'Nr. QR-codes gescand / website bezoeken',
      incentiveRequested: 'Cadeaus aangevraagd',
      questionnaireStarted: 'Vragen gestart',
      questionnaireCompleted: 'Ingevulde vragen',
      incentiveVoucherReserved: 'Cadeau vouchers gereserveerd',
      incentiveVoucherUsed: 'Cadeau vouchers gebruikt',
      incentiveVoucherExpired: 'Cadeau vouchers verlopen',
      resetVoucherCode: 'Voucher code gereset',
    },
    referral: {
      reached: 'Aantal links gestuurd naar vrienden geopend',
      incentiveRequested: 'Cadeaus aangevraagd',
      questionnaireStarted: 'Vragen gestart',
      questionnaireCompleted: 'Ingevulde vragen',
      incentiveVoucherReserved: 'Cadeau vouchers gereserveerd',
      incentiveVoucherUsed: 'Cadeau vouchers gebruikt',
      incentiveVoucherExpired: 'Cadeau vouchers verlopen',
      resetVoucherCode: 'Voucher code gereset',
    },
  },
}

const tooltips = {
  generate_files: 'Genereer Bestanden',
  send_email: 'Stuur E-mail',
  download_user_data: 'Gebruikersgegevens Downloaden',
  download_creative: 'Drukbestanden downloaden',
}

const flyer = {
  title: 'Flyerontwerp',
  front: 'Voorkant',
  landing_page: 'Klantlandingspagina',
  landing_page_description:
    'Deze tekst verschijnt nadat je klant de QR-code heeft gescand.',
  main_campaign: 'Hoofdcampagne',
  heading: 'Titel',
  add_text: 'Voeg tekst toe',
  referral_campaign: 'Verwijzingscampagne',
  info_card: {
    flyer_requirements: 'Vereisten voor flyers',
    safety_margin: 'Veiligheidsmarge',
    cutting_margin: 'Snijmarge',
    yellow: 'Geel',
    red: 'Rood',
    safety_size: ': 210 x 148mm',
    cutting_size: ': 216 x 154mm',
  },
}

const campaign_categories = {
  title: 'Campagne Categorie',
  generic: 'Algemeen',
  birthday: 'Verjaardag',
  lottery: 'Loterij',
  generic_description:
    'Upload jouw databestand en creëer en verstuur jouw campagne',
  birthday_description:
    'Zorg dat je aandacht en waardering geeftop de verjaardagen van jouw doelgroep',
  lottery_description:
    'Haal de resultaten van de loterijtrekking open deel de prijzen met de winnaars',
}
const api_integration = {
  generic_header: 'Algemene Campagne',
  generic_text:
    'Selecteer alstublieft de datumreeks voor de klantgegevens die u wilt downloaden van de API.',
  lottery_text:
    'Selecteer alstublieft één of meerdere loterijinschrijvingsformulieren voor de klantgegevens die u wilt downloaden van de API.',
  lottery_header: 'Loterij Campagne',
  sync: 'Synchroniseer API-verbinding',
}

const incentive_errors_modal = {
  delete_error_modal_title: 'De stimulans kan niet worden verwijderd',
  delete_incentive_item_is_used:
    'Cadeau item is in {{numberOfCampaigns}} gebruik',
  delete_incentive_with_used_voucher_codes:
    'Kan cadeau item niet verwijderen met gebruikte voucher codes',
  update_error_modal_title:
    'Dit geschenk is te zien in {{numberOfCampaigns}} campagnes',
  update_incentive_item_is_used:
    'De geselecteerde incentive is momenteel opgenomen in {{numberOfCampaigns}} campagnes. Als u deze wilt deactiveren, moet u de incentive uit de campagnes deselecteren',
}

const incentives_store = {
  title: 'Stimulansen',
  new_incentive: 'Nieuw cadeau',
  featured: 'Uitgelicht',
  product_info: 'Product informatie',
  price: 'Prijs',
  category: 'Categorie',
  description: 'Omschrijving',
  claimed: 'Geclaimd',
  disabled_active: 'Uitgeschakeld/Actief',
  pcs: 'per stuk',
  add_incentive: 'Nieuw cadeau toevoegen',
  active: 'Actief',
  status: 'Status',
  uploaded_files: 'Geüploade bestanden',
  add: 'Cadeau toevoegen',
  no_incentives: 'No incentives added for this customer yet.',
  please_add: 'Please add a new incentive',
  featured_tooltip: 'Uitgelicht in {{numberOfCampaigns}} campagnes',
  previous: 'Vorige',
  next: 'Volgende',
  all_customers: 'Alle gebruikers',
  all_categories: 'Alle categorieën',
  search_placeholder: 'Zoek cadeau',
  pagination_info:
    'Pagina {{currentPage}} van {{totalPages}} ({{startIndex}}-{{endIndex}} van de {{totalIncentives}} rijen getoond)',
}

const ec = {
  hello: 'Beste',
  dear: 'Beste',
  yes_button: 'Bekijk',
  back: 'Terug',
  next: 'Volgende',
  happiness_check_button: 'Geluk Controle',
  finish: 'Voltooien',
  home: {
    header: 'Gefeliciteerd!',
    text: 'Bekijk welke mooie prijs je hebt gewonnen.',
  },
  choose_incentive: {
    title: 'Speciaal voor jou!',
    sub_title: 'Veel plezier ermee.',
    header: 'KIES EEN CADEAU',
    header_lottery: 'JOUW PRIJS',
    validation: 'Kies een cadeau om verder te gaan',
    code_has_expired:
      'Uw vouchercode is verlopen, selecteer opnieuw een incentive',
  },
  happiness_check: {
    title: 'Laat ons weten over je ervaring',
  },
  invite: {
    title: 'Er mag ook een cadeau aan een bekende gestuurd worden!',
    sub_title: 'Dit kan makkelijk door de onderstaande link te delen:',
    share_msg: 'Ik heb een cadeau voor jou',
    share_msg_heart_foundation:
      'Ik mocht als collectant van de Hartstichting een cadeautje uitzoeken. Jij mag dit ook! Wat kies jij uit?',
    copy: 'Kopiëren',
    share: 'Delen',
  },
  questionnaire: {
    title: 'Graag de benodigde gegevens hieronder invullen.',
    termsAndConditions: {
      text1: 'Ja, ik wil het graag ontvangen en ga er mee akkoord dat:',
      text2:
        'Mijn persoonsgegevens worden gebruikt voor het verwerken van mijn bestelling',
      text3: 'contact met mij mag opnemen ten behoeve van relevante acties',
      text4:
        'De persoonsgegevens zijn nodig om de bestelling te verwerken. Uiteraard worden de persoonsgegevens beschermd overeenkomstig de privacy policy van',
    },
  },
  success: {
    title: 'Hartelijk dank!',
    flyer_title: 'Hartelijk dank!',
    sub_title: 'Details zijn via de e-mail verstuurd.',
    title_with_happiness_check:
      'Klaar! Een persoonlijke mening wordt gewaardeerd.',
  },
  expired: {
    title: 'Oeps! De QR-code werkt niet meer.​',
    description:
      'Dit kan zijn doordat de campagne is afgelopen of omdat het cadeau al is geclaimd.',
    termsAndConditions: 'Terms and Conditions nl',
  },
}

const ec_flow_texts = {
  home: 'Home Page',
  incentive: 'Incentive Page',
  questionnaire: 'Questionnaire Page',
  invite: 'Invite Page',
  happiness_check: 'Happiness Check Page',
  success: 'Success Page',
  expired: 'Expired Page',
  sub_header:
    'Hieronder kunt u de tekst van de schermen voor uw eindklant kiezen en aanpassen',
  end_user: 'Eindgebruiker',
  referral: 'Vrienden',
  original_receiver: 'Originele ontvanger',
  invited: 'Ontvanger op uitnodiging',
  header: 'Koptekst',
  text: 'Tekst',
  termsAndConditions: 'Terms & Conditions tekst',
  termsAndConditionsEndUser: 'Terms & Conditions voor de eindgebruiker',
  termsAndConditionsReferral: 'Terms & Conditions voor doorverwijzers',
  termsAndConditionsMarketing: 'Terms & Conditions voor marketing',
  termsAndConditionsSummary: 'Terms & Conditions samenvatting',
  subject: 'Onderwerp',
  email_text: 'E-mailtekst',
  preview_link: 'Voorbeeld link',
  copy_link:
    'Kopieer de link om direct in uw browser/mobiele telefoon een voorbeeld te bekijken',
  get_link: 'Link ophalen',
}

const emails = {
  header: 'E-mails',
  text: 'Hier kunt u de e-mailtekst wijzigen die uw eindklanten gaan ontvangen',
}

const sku_builder = {
  add_sku: 'Nieuwe SKU Code',
  update_sku: 'SKU bijwerken',
  sku_code: 'SKU Code',
  sku_list: 'SKU Lijst',
  no_sku: 'SKU bestaat niet',
  please_add_sku: 'Voeg SKU Code toe',
  incentives: 'Cadeau winkel',
  search_placeholder: 'Zoeken',
  add_sku_code: 'SKU Code toevoegen',
  update_sku_code: 'SKU Code bijwerken',
  featured_tooltip: 'Uitgelicht in {{numberOfIncentives}} cadeaus',
  assigned: 'Toegewezen',
  assign_incentive: 'Cadeau toewijzen',
}

const translation: Translations = {
  emails,
  ec_flow_texts,
  ec,
  locale: 'nl-NL',
  yes: 'Ja',
  no: 'Nee’',
  save_draft: 'Concept opslaan',
  next_step: 'Volgende stap',
  save_campaign: 'Campagne opslaan',
  language: 'Taal',
  calendar: 'Kalender',
  loading: 'Laden',
  create: 'Creëren',
  close: 'Sluit',
  details: 'Details',
  cancel: 'Annuleren',
  formal: 'Formeel',
  informal: 'Informeel',
  save_to_preview: 'Concept opslaan om de preview te zien',
  download_front_pdf: 'Download voorzijde als pdf',
  download_back_pdf: 'Download achterzijde als pdf',
  upload_csv_error:
    'Het bestand heeft niet de juiste indeling. Gebruik alstublieft dezelfde indeling als in het voorbeeldbestand!',
  repeat_incentive: 'Voeg dezelfde cadeaus toe voor vrienden',
  dear: 'Beste',
  hello: 'Beste',
  current_month: 'Huidige maand',
  required: 'Verplicht',
  sku_not_exist:
    'Deze SKU-code "{{sku}}" bestaat niet in de geüploade gegevens',
  sku_exist: 'Deze SKU-code "{{sku}}" bestaat al',
  skuCodes_not_match_data_upload:
    'SKU-codes "{{sku}}" bestaan in de geüploade gegevens, maar niet in de geselecteerde incentive-items',
  campaigns_on: 'campagnes op',
  delete: 'Verwijderen',
  delete_campaign: 'Campagne verwijderen',
  are_you_sure: 'Weet u zeker dat u deze campagne wilt verwijderen?',
  deleting_campaign:
    'Als u de campagne verwijdert, wordt deze uit de lijst en het dashboard verwijderd.',
  multiselect_placeholder: 'Selecteer een optie',
  previous_campaign: 'Vorige campagne',
  day: 'Dag',
  edit: 'Bewerk',
  disable: 'Uitschakelen',
  api_integration,
  next: 'Volgende Scherm',
  save: 'Opslaan',
  mobile_view: 'Mobiele weergave',
  pick_a_date: 'Kies een datum',
  salutation: 'Begroeting (Aanhef)',
  previous_screen: 'Vorige scherm',
  languages,
  progress_bar,
  campaign_details,
  navigation,
  campaign_list,
  questionnaire,
  validation_messages,
  end_users,
  file_upload,
  optional_question,
  incentives,
  add_incentive,
  compose_creative,
  offline,
  confirmation,
  happiness_check,
  online,
  print_files_download,
  incentive_categories,
  dashboard_labels,
  tooltips,
  flyer,
  campaign_categories,
  incentive_errors_modal,
  incentives_store,
  sku_builder,
}

export default translation
