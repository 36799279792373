import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import './App.css'
import '../app/globals.css'
import { CampaignLayout } from './components/campaign-layout'
import Layout from './components/layout'
import Agenda from './pages/agenda'
import CreateCampaign from './pages/campaign/campaign-details/create'
import UpdateCampaignDetails from './pages/campaign/campaign-details/update'
import EndUsers from './pages/campaign/end-users'
import HappinessCheck from './pages/campaign/happiness-check'
import Incentives from './pages/campaign/incentive'
import List from './pages/campaign/list'
import Questionnaire from './pages/campaign/questionnaire'
import Dashboard from './pages/dashboard'
import { tokenState } from './store/atoms/token'
import ActionPanel from './pages/action-panel'
import Confirmation from './pages/campaign/confirmation'
import ScrollToTop from './util/scrollToTop'
import { IncentiveForm } from './components/create-incentive/incentive-form'
import IncentivesStore from './pages/incentive-store'
import EndUserLayout from './components/end-user-layout'
import EcFlowIndex from '../../end-users/src/components/pages/index'
import ChooseIncentive from '../../end-users/src/components/pages/choose-incentive'
import QuestionnaireEndUsers from '../../end-users/src/components/pages/questionnaire'
import Invite from '../../end-users/src/components/pages/invite'
import HappinessCheckEndUsers from '../../end-users/src/components/pages/happiness-check'
import Success from '../../end-users/src/components/pages/success'
import Expired from '../../end-users/src/components/pages/expired'
import EndUserPreviewLayout from './components/end-user-preview-layout'
import Preview from './components/ec-flow-preview'
import Emails from './pages/campaign/emails'
import SkuFormBuilder from './pages/sku-builder/sku-form'
import AdminPage from './pages/admin'

const ComposeCreative = React.lazy(
  () => import('./pages/campaign/compose-creative'),
)

function App() {
  const { getIdTokenClaims, loginWithRedirect } = useAuth0()
  const { isLoading: isLoadingAuthUser } = useAuth0()
  const [idToken, setToken] = useRecoilState(tokenState)

  useEffect(() => {
    !idToken &&
      getIdTokenClaims().then((token) => {
        const expiredAt = (token?.exp ?? 0) * 1000
        if (!token || expiredAt < new Date().getTime()) loginWithRedirect()
        else setToken(token)
      })
  }, [idToken, getIdTokenClaims, loginWithRedirect, setToken])

  if (isLoadingAuthUser || !idToken) return <>Loading</>

  return (
    <>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<ActionPanel />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="agenda" element={<Agenda />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="incentives">
            <Route index element={<IncentivesStore />} />
            <Route path="create" element={<IncentiveForm />} />
            <Route path="update/:incentiveId" element={<IncentiveForm />} />
            <Route path="sku-list">
              <Route index element={<IncentivesStore />} />
              <Route path="create" element={<SkuFormBuilder />} />
              <Route path="update/:id" element={<SkuFormBuilder />} />
            </Route>
          </Route>
          <Route path="dashboard/:campaignId" element={<Dashboard />} />
          <Route path="campaigns">
            <Route path="list" element={<List />} />

            <Route element={<CampaignLayout />}>
              <Route path="create" element={<CreateCampaign />} />
              <Route path="details/:id" element={<UpdateCampaignDetails />} />
              <Route path="end-users/:id" element={<EndUsers />} />
              <Route
                path="questionnaire/:id"
                element={<Questionnaire referral={false} />}
              />
              <Route
                path="referral-questionnaire/:id"
                element={<Questionnaire referral={true} />}
              />
              <Route
                path="incentives/:id"
                element={<Incentives referral={false} />}
              />
              <Route
                path="referral-incentives/:id"
                element={<Incentives referral={true} />}
              />
              <Route path="emails/:id" element={<Emails />} />
              <Route
                path="compose-creative/:id"
                element={<ComposeCreative />}
              />
              <Route path="happiness-check/:id" element={<HappinessCheck />} />
              <Route element={<EndUserLayout />}>
                {/* End users EC flow */}
                <Route
                  path="/campaigns/ec-flow/:id/end-user/home"
                  element={<Preview Component={EcFlowIndex} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/end-user/incentive"
                  element={<Preview Component={ChooseIncentive} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/end-user/questionnaire"
                  element={<Preview Component={QuestionnaireEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/end-user/invite"
                  element={<Preview Component={Invite} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/end-user/happiness-check"
                  element={<Preview Component={HappinessCheckEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/end-user/success"
                  element={<Preview Component={Success} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/end-user/expired"
                  element={<Preview Component={Expired} />}
                />
                {/* Referral users EC flow */}
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/home"
                  element={<Preview Component={EcFlowIndex} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/incentive"
                  element={<Preview Component={ChooseIncentive} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/questionnaire"
                  element={<Preview Component={QuestionnaireEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/invite"
                  element={<Preview Component={Invite} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/happiness-check"
                  element={<Preview Component={HappinessCheckEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/success"
                  element={<Preview Component={Success} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/referral-user/expired"
                  element={<Preview Component={Expired} />}
                />
              </Route>

              <Route element={<EndUserPreviewLayout />}>
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/home"
                  element={<Preview Component={EcFlowIndex} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/incentive"
                  element={<Preview Component={ChooseIncentive} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/questionnaire"
                  element={<Preview Component={QuestionnaireEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/invite"
                  element={<Preview Component={Invite} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/happiness-check"
                  element={<Preview Component={HappinessCheckEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/success"
                  element={<Preview Component={Success} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/end-user/expired"
                  element={<Preview Component={Expired} />}
                />

                {/* Preview of Referral User */}
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/home"
                  element={<Preview Component={EcFlowIndex} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/incentive"
                  element={<Preview Component={ChooseIncentive} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/questionnaire"
                  element={<Preview Component={QuestionnaireEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/invite"
                  element={<Preview Component={Invite} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/happiness-check"
                  element={<Preview Component={HappinessCheckEndUsers} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/success"
                  element={<Preview Component={Success} />}
                />
                <Route
                  path="/campaigns/ec-flow/:id/preview/referral-user/expired"
                  element={<Preview Component={Expired} />}
                />
              </Route>
            </Route>
            <Route path="confirmation/:id" element={<Confirmation />} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
